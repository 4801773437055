<template>
  <div ref="root">
    <slot name="placeholder" :show-placeholder="showPlaceholder" />
    <slot :show="showComponent" />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'

const showPlaceholder = ref(true)
const showComponent = ref(false)

const emit = defineEmits(['show'])
const root = ref<HTMLDivElement | null>(null)

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    showPlaceholder.value = false
    showComponent.value = true

    emit('show')
  } else {
    showComponent.value = false
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
